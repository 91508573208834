import _ from 'lodash';
import { Redirect, Route, Switch } from 'react-router-dom';
import React, { useContext } from 'react';

import { UserContext } from '../context';

import UnderMaintenance from './Public/UnderMaintenance';
import MainDashboard from './MainDashboard';

const Dashboard = ({ location }) => {
	const session = useContext(UserContext);

	const nama_sekolah = _.get(session, 'sekolah.nama');
	const perbaikan = _.get(session, 'sekolah.perbaikan');

	const uri		= _.get(location, "pathname", "");
	const { list_menu = [], list_route = [] } = session;
	const default_to = _.get(list_menu, [0, 'parent', 'to'], "/");
	const listRouteUjian = list_route.filter(o => o.withoutHeader)

	if (!session.is_login) { return <Redirect to="/login" />; }
	if (session.is_login && perbaikan) { return <UnderMaintenance nama={nama_sekolah}/>}
	if (session.is_login && _.isEqual(uri, "/")) { return <Redirect to={default_to}/> }

	// console.log({ listRouteUjian });
	return <Switch>
		{_.toArray(listRouteUjian).map((item, index) => {
			return <Route exact key={`menu-${index}`} {...item}/>
		})}
		<Route key="MAINDASHBOARD" path="/" component={MainDashboard}/>
	</Switch>
}

export default Dashboard;