import Loadable from "../../../components/Loadable"

const Penjadwalan = Loadable(() => import('./Penjadwalan/Penjadwalan'));
const SesiMateriBaru = Loadable(() => import('./Penjadwalan/SesiMateriBaru'));
const DetilSesiMateri = Loadable(() => import('./Penjadwalan/DetilSesiMateri'));

const DaftarBankMateri = Loadable(() => import('./BankMateri/DaftarBankMateri'));
const DetilMateri = Loadable(() => import('./BankMateri/DetilMateri'));
const TambahMateri = Loadable(() => import('./BankMateri/TambahMateri'));

const DaftarMateriOrbit = Loadable(() => import('./Orbit360/DaftarMateriOrbit'));
const DetilDaftarMateriOrbit = Loadable(() => import('./Orbit360/DetilDaftarMateriOrbit'));
const DetilMateriOrbit = Loadable(() => import('./Orbit360/DetilMateriOrbit'));

const NilaiIndex = {
	DaftarMateriOrbit, DetilMateriOrbit, DetilDaftarMateriOrbit, 
	Penjadwalan, SesiMateriBaru, DetilSesiMateri,
	DaftarBankMateri, DetilMateri, TambahMateri
}

export default NilaiIndex;
