import Loadable from "../../../components/Loadable";

const DaftarJadwalGuruMapel = Loadable(() => import("./JadwalGuruMapel/DaftarJadwalGuruMapel"));
const DetilJadwalGuruMapel = Loadable(() => import("./JadwalGuruMapel/DetilJadwalGuruMapel"));

const DaftarJadwalKelas = Loadable(() => import("./JadwalKelas/DaftarJadwalKelas"));
const DetilJadwalKelas = Loadable(() => import("./JadwalKelas/DetilJadwalKelas"));
const EditJadwalKelas = Loadable(() => import("./JadwalKelas/EditJadwalKelas"));

const PengaturanJadwal = Loadable(() => import("./PengaturanJadwal/PengaturanJadwal"));

export default {
	DaftarJadwalGuruMapel, DetilJadwalGuruMapel,
	DaftarJadwalKelas, DetilJadwalKelas, EditJadwalKelas,
	PengaturanJadwal
};