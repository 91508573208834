import Loadable from "../components/Loadable";

import Ikhtisar from '../views/WaliMurid/Ikhtisar/Index';
import Pengumuman from '../views/Karyawan/Pengumuman/Index';
import KuesionerView from "../views/WaliMurid/KuesionerView/Index";
import MateriMurid from "../views/Murid/MateriMurid";
import Tugas from "../views/Murid/Tugas/Index";

const Profil = Loadable(() => import('../views/WaliMurid/Profil'));

const base_url = '';
export default [

	{ path: [base_url,'pengumuman'].join('/'), component: Pengumuman.DaftarPengumumanView },
	{ path: [base_url,'pengumuman', ':id'].join('/'), component: Pengumuman.DetilPengumumanView },

	{ path: [base_url, 'ikhtisar', 'umum'].join('/'), component: Ikhtisar.IkhtisarUmum },
	{ path: [base_url, 'ikhtisar', 'akademik'].join('/'), component: Ikhtisar.IkhtisarAkademik },
	{ path: [base_url, 'ikhtisar', 'kesiswaan'].join('/'), component: Ikhtisar.IkhtisarKesiswaan },
	{ path: [base_url, 'ikhtisar', 'tata-tertib'].join('/'), component: Ikhtisar.IkhtisarTataTertib},
	{ path: [base_url, 'ikhtisar', 'keuangan'].join('/'), component: Ikhtisar.IkhtisarKeuangan},

	{ path: [base_url, 'materi', 'penjadwalan'].join('/'), component: MateriMurid.Penjadwalan},
	{ path: [base_url, 'materi', 'penjadwalan', ":id"].join('/'), component: MateriMurid.DetilSesiMateri},
	{ path: [base_url, 'materi', 'mata-pelajaran'].join('/'), component: MateriMurid.MateriPembelajaran},
	{ path: [base_url, 'materi', 'mata-pelajaran', ":mapel_id"].join('/'), component: MateriMurid.DaftarMateriMapel},
	{ path: [base_url, 'materi', 'mata-pelajaran', ":mapel_id", ":id"].join('/'), component: MateriMurid.DetilSesiMateriMapel},

	{ path: [base_url, 'tugas', 'jadwal'].join('/'), component: Tugas.TugasMurid },
	// { path: [base_url, 'tugas', 'jadwal', ':id'].join('/'), component: Tugas.UjiTunggu, withoutHeader: true },
	// { path: [base_url, 'tugas', 'soal', ':id'].join('/'), component: Tugas.PerSoal, withoutHeader: true },
	{ path: [base_url, 'tugas', 'hasil'].join('/'), component: Tugas.TugasMuridHasil },
	{ path: [base_url, 'tugas', 'hasil', ":id"].join('/'), component: Tugas.DetilTugasHasil, withoutHeader: true },

	{ path: [base_url, 'kuesioner'].join('/'), component: KuesionerView.DaftarKuesioner},
	{ path: [base_url, 'kuesioner', ':_id'].join('/'), component: KuesionerView.DetilKuesioner},

	{ path: [base_url, 'profil'].join('/'), component: Profil },
];