import Loadable from "../../../components/Loadable";

const IkhtisarUmum = Loadable(() => import("./IkhtisarUmum"));
const IkhtisarAkademik = Loadable(() => import("./IkhtisarAkademik"));
const IkhtisarKesiswaan = Loadable(() => import("./IkhtisarKesiswaan"));
const IkhtisarTataTertib = Loadable(() => import("./IkhtisarTataTertib"));
const IkhtisarKeuangan = Loadable(() => import("./IkhtisarKeuangan"));

export default {
	IkhtisarKeuangan,
	IkhtisarTataTertib,
	IkhtisarKesiswaan,
	IkhtisarAkademik,
	IkhtisarUmum
};