import React, { Component } from 'react';
import styled from 'styled-components';
import MoleculeDropdown from './MoleculeDropdown';
import { UserContext } from '../../../../context';
import _ from "lodash";
class MoleculeMenu extends Component {
  state = { selected: "" } 
  
  menu_nested = () => {
  
    const acl = []
    .concat(_.get(this.context, "list_acl", []))
    .filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
    
    const menu_list = _.chain(this.context).get("list_menu", []).map(o => {
      const parent = _.get(o, "parent", {})
      const map_child = _.get(o, "child", []).map(oo => _.set(oo, "parent", parent.id))
      return [parent, ...map_child]
    }).flatten().value()
    const menu_filter = menu_list.filter(m => acl.filter(a => a.id === m.id));
    const nested = [];

    menu_filter.forEach(m => {
      if (m.parent) {
        const index = nested.findIndex(n => n.id === m.parent);
        nested[index].child.push(m);
      } else {
        nested.push({...m, child: []})
      }
    });
    return nested;
  };
  handleSelected = (id) => {
    
    this.setState({selected: id});
  }  

  componentDidUpdate(pp, ps){
    const { selected } = this.state
    const { search } = this.props

    if(!_.isEqual(selected, ps.selected) && !_.isEqual(search, pp.search)){
      const element = document.getElementById(selected)
      element.scrollIntoView() 
    } 
  }
  render(){
    const { selected } = this.state
    const menus = this.menu_nested()
    const search = this.props.search.toLowerCase();
    
    return (<Style>
      <div className='dashboard-menu'>
        {menus.map(item => <MoleculeDropdown
          {...item}
          selected={selected}
          handleSelected={this.handleSelected}
          search={search}
          history={this.props.history}
          resetSearch={this.props.resetSearch}
          key={item.id}
        />)}
      </div>
    </Style>)
  }
} 

const Style = styled.div`
    height: calc(100vh - 248px);
    align-content: start;
    overflow: auto;
    scrollbar-width: none;

    ::-webkit-scrollbar {
        width: 0px;
    }

    .dashboard-menu {
        display: grid;
        gap: .5em;
    }

    @media (max-width: 480px) {
        height: calc(100vh - 196px);
    }
`;

MoleculeMenu.contextType = UserContext;
export default MoleculeMenu;