import React, { useContext } from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { UserContext } from '../../../../context';
import Media from 'react-media';

const MoleculeToggleNav = () => {
    const { state, action } = useContext(UserContext);

    return (<Media query={{maxWidth: 768}}>
        {v => !v && <Style onClick={action.handleNav} nav={state.navigation.active || state.navigation.hover} >
            <Icon size="big" color={'orange'} name={state.navigation.active ? 'imp imp-menu-tutup' : 'imp imp-menu-buka'} />
            {(state.navigation.active || state.navigation.hover ) && <p>Sistem Manajemen Sekolah</p>}
        </Style>}
    </Media>);
};

const Style = styled.div`
    gap: 16px;
    margin-top: 6px;
    cursor: pointer;
    position: relative;
    height: 36px;
    user-select: none;
    margin: 16px;
    display: grid;
    grid-template-columns: ${props => props.nav ? '37px auto' : '1fr'};
    align-items: center;

    i.icon {
        place-self: center;
        margin: 0;
    }

    p {
        font-size: 14px;
        max-width: 180px;
    }

    &:target {
        user-select: none;
    }

    @media (max-width: 480px) {
        width: 40px;
        margin-top: 0;
        grid-template-columns: 1fr;
        gap: 0;
        margin: 0 8px;

        p {
            display: none;
        }
    }
`;

export default MoleculeToggleNav;