import _ from "lodash";

import ppdb from './ppdb';
import murid from './murid';
import karyawan from './karyawan';
import waliMurid from './waliMurid';

const routes = [
	...ppdb.map(o => _.assign({ list_model: ["ppdb"] }, o)),
	...waliMurid.map(o => _.assign({ list_model: ["waliMurid"] }, o)),
	...murid.map(o => _.assign({ list_model: ["murid"] }, o)),
	...karyawan.map(o => _.assign({ list_model: ["karyawan"] }, o))
];
export default routes;