import Loadable from '../../../components/Loadable';

const TugasPenjadwalanView  = Loadable(() => import("./PenjadwalanTugas/TugasPenjadwalanView"));
const DetilSesiTugasJadwal  = Loadable(() => import("./PenjadwalanTugas/DetilSesiTugasJadwal"));
const DetilTugasJadwal  = Loadable(() => import("./PenjadwalanTugas/DetilTugasJadwal"));
const TambahTugasJadwal  = Loadable(() => import("./PenjadwalanTugas/TambahTugasJadwal"));

const DaftarUjianJadwal  = Loadable(() => import("./PenjadwalanUjian/DaftarJadwalUjian"));
const DetilSesiUjianJadwal  = Loadable(() => import("./PenjadwalanUjian/DetilSesiUjianJadwal"));
const DetilUjianJadwal  = Loadable(() => import("./PenjadwalanUjian/DetilUjianJadwal"));
const TambahUjianJadwal  = Loadable(() => import("./PenjadwalanUjian/TambahUjianJadwal"));

const DaftarPaket  = Loadable(() => import("./BankSoal/DaftarPaket"));
const DaftarPaketBaru  = Loadable(() => import("./BankSoal/DaftarPaketBaru"));
const DetilPaket  = Loadable(() => import("./BankSoal/DetilPaket"));
const DetilSoal  = Loadable(() => import("./BankSoal/DetilSoal"));

const DaftarHasil  = Loadable(() => import("./Hasil/DaftarHasil/DaftarHasil"));
const DetilNilai  = Loadable(() => import("./Hasil/DetilNilai/DetilNilai"));
const DetilNilaiPeserta  = Loadable(() => import("./Hasil/DetilNilaiPeserta/DetilNilaiPeserta"));
const Isian  = Loadable(() => import("./Hasil/InputNilai/Isian"));
const Uraian  = Loadable(() => import("./Hasil/InputNilai/Uraian"));
const Pengaturan  = Loadable(() => import("./Hasil/Pengaturan/Pengaturan"));

const DaftarBankSoalOrbit  = Loadable(() => import("./BankSoalOrbit/DaftarBankSoalOrbit"));
const DetilDaftarBankSoalOrbit  = Loadable(() => import("./BankSoalOrbit/DetilDaftarBankSoalOrbit"));
const DetilBankSoalOrbit  = Loadable(() => import("./BankSoalOrbit/DetilBankSoalOrbit"));

const DetilSoalSnapshot = Loadable(() => import("./DetilSoalSnapshot/DetilSoalSnapshot"));

export default {
	DaftarHasil,
	DetilNilai,
	DetilNilaiPeserta,
	Isian,
	Uraian,
	Pengaturan,
	
	DetilSoal,
	DetilPaket,
	DaftarPaket,
	DaftarPaketBaru,

	TugasPenjadwalanView,
	DetilSesiTugasJadwal,
	DetilTugasJadwal,
	TambahTugasJadwal,

	DetilSoalSnapshot,

	DaftarUjianJadwal,
	DetilSesiUjianJadwal,
	DetilUjianJadwal,
	TambahUjianJadwal,

	DaftarBankSoalOrbit,
	DetilDaftarBankSoalOrbit,
	DetilBankSoalOrbit,
};