import Loadable from "../../../components/Loadable";

const DaftarNilai = Loadable(() => import('./DaftarNilai/DaftarNilai'));
const DaftarKelas = Loadable(() => import('./DaftarNilai/DaftarKelas'));

const DaftarNilaiUS = Loadable(() => import('./NilaiUjianSekolah/DaftarNilaiUS'));
const DetilNilaiUS = Loadable(() => import('./NilaiUjianSekolah/DetilNilaiUS'));

const DaftarNilaiUN = Loadable(() => import('./NilaiUjianNasional/DaftarNilaiUN'));
const DetilNilaiUN = Loadable(() => import('./NilaiUjianNasional/DetilNilaiUN'));

const Absensi = Loadable(() => import('./Absensi/DaftarAbsensi'));
const InputAbsensi = Loadable(() => import('./Absensi/InputAbsensi'));
const DetilHistoryAbsensi = Loadable(() => import('./Absensi/DetilHistoryAbsensi'));

const DaftarKumulatifNilai = Loadable(() => import('./DaftarKumulatifNilai/DaftarKumulatifNilai'));
const DaftarKumulatifHafalan = Loadable(() => import('./DaftarKumulatifHafalan/DaftarKumulatifHafalan'));
const DetilKumulatifNilai = Loadable(() => import('./DaftarKumulatifNilai/DetilKumulatifNilai'));
const DKNDaftarNilai = Loadable(() => import('./DaftarKumulatifNilai/DaftarNilai'));
const DaftarNilaiHafalan = Loadable(() => import('./DaftarNilaiHafalan/DaftarNilaiHafalan'));

const DaftarPeringkat = Loadable(() => import('./DaftarPeringkat/DaftarPeringkat'));

const DaftarKumulatifUSUN = Loadable(() => import('./DaftarKumulatifUSUN/DaftarKumulatifUSUN'));
const DetilKumulatifUSUN = Loadable(() => import('./DaftarKumulatifUSUN/DetilKumulatifUSUN'));
const DetilKumulatifKBM = Loadable(() => import('./DaftarKumulatifUSUN/DetilKumulatifKBM'));

const NilaiIndex = {
	DaftarKelas, DaftarNilai, DaftarNilaiHafalan, DaftarKumulatifHafalan,
	DaftarKumulatifNilai, DetilKumulatifNilai, DKNDaftarNilai,
	DaftarPeringkat, Absensi, InputAbsensi, DetilHistoryAbsensi,
	DaftarNilaiUN, DetilNilaiUN,
	DaftarNilaiUS, DetilNilaiUS,
	DaftarKumulatifUSUN, DetilKumulatifUSUN, DetilKumulatifKBM,
}

export default NilaiIndex;
