import Loadable from "../../../components/Loadable";

const TugasMurid = Loadable(() => import("./Tugas"));
const UjiTunggu = Loadable(() => import("./UjiTunggu"));
const PerSoal = Loadable(() => import("./PerSoal"));
const DetilTugasHasil = Loadable(() => import("./DetilTugasHasil"));
const TugasMuridHasil = Loadable(() => import("./TugasHasil"));

export default {
	DetilTugasHasil,
	PerSoal,
	UjiTunggu,
	TugasMurid,
	TugasMuridHasil,
};