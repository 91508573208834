import Loadable from "../../../components/Loadable";

const DetilKBM = Loadable(() => import("./DetilKBM"));
const DetilDaftarKelas = Loadable(() => import("./DetilDaftarKelas"));
const DaftarKBM = Loadable(() => import("./DaftarKBM"));
const DaftarKelas = Loadable(() => import("./DaftarKelas"));
const KelasBaru = Loadable(() => import("./KelasBaru"));
const DaftarKelompokHafalan = Loadable(() => import("./DaftarKelompokHafalan"));
const DetilKelompokHafalan = Loadable(() => import("./DetilKelompokHafalan"));

export default {
	DetilKelompokHafalan,
	DaftarKelompokHafalan,
	DaftarKBM, KelasBaru , DaftarKelas ,DetilKBM, DetilDaftarKelas
};