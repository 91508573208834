import _ from "lodash";
import styled from 'styled-components';
import { Header, Icon } from 'semantic-ui-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import React, { useContext } from 'react';

import { UIContext, UserContext } from '../context';
import OrganismDashboardLeft from '../collection/Sidebar/components/organisms/OrganismDashboardLeft';

import logo_sekolah from '../assets/logo_sekolah.png';

const ISMS = <p className="unselectable">Sistem Manajemen Sekolah</p>;

const Layout = styled.div`
	display: grid;
	position: absolute;
	width: 100%;
	height: 100%;
	grid-template-columns: auto 1fr;

	> .top-navigation { display: none; }
	
	@media only screen and (max-width: 768px) {
		grid-template-rows: auto 1fr;
		> .top-navigation {
			background: #f4f6f8;
			grid-column: span 2;
			display: grid;
			grid-template-columns: 3em 1fr;
			padding: .6em .4em;
			align-items: center;
			
			> div:first-child {
				text-align: center;
			}
			> div:last-child {
				display: grid;
				grid-gap: 0 .4em;
				align-items: center;
				grid-template-rows: auto auto;
				grid-template-columns: 3em 1fr;
				> div:first-child { text-align: center; }	
			}
		}	
	}
	@media only screen and (min-width: 768px) {
		
	}
	@media only screen and (min-width: 992px) {
				
	}
	@media only screen and (min-width: 1600px) {
		
	}
`;

const TopNavigation = ({ visible, toggle, nama, isms }) => {
	return <div className="top-navigation">
		<div><Icon size="big" basic color="orange" name={visible ? 'imp imp-menu-tutup' : 'imp imp-menu-buka'} className="sidebar-btn" onClick={toggle} /></div>
		<div>
			<div style={{gridRow: "span 2"}}>
				<img src={logo_sekolah} width="85%" alt="SMA" />
			</div>
			<div>{nama}</div>
			<div>{isms}</div>
		</div>
	</div>
}

const Content = styled.div`
	flex: 1;
	display: flex;
	overflow: hidden;
	overflow-y: auto;
`;

const MainDashboard = (props) => {
	const session = useContext(UserContext);
	const sessionUi = useContext(UIContext);
	
	const { history } = props;
	const { list_menu = [], list_route = [] } = session;
	
	const default_to = _.get(list_menu, [0, 'parent', 'to'], "/");
	const nama_sekolah = _.get(session, 'sekolah.nama');
	const listRoute = list_route.filter(o => !_.get(o, "withoutHeader", false) )
	const webview = _.get(sessionUi, "webview", false)
	return (<Layout>
		{!webview && <TopNavigation
				visible={_.get(session, 'state.navigation.active') || _.get(session, 'state.navigation.hover')} 
				toggle={_.get(session, 'action.handleNav')}
				nama={<Header as="h4" className="unselectable" color="theme">{nama_sekolah}</Header>}
				isms={ISMS}
			/>
		}
		{webview ? <div /> : <div><OrganismDashboardLeft history={history} /></div>}
		<Content>
			<Switch>
				{_.toArray(listRoute).map((item, index) => <Route exact key={`menus-${index}`} {...item} />)}
				<Route key="DEFAULT" path="/" component={() => <Redirect to={default_to} />}/>
			</Switch>
		</Content>
	</Layout>);
}

export default MainDashboard;