import Loadable from "../../../components/Loadable";

const Penjadwalan = Loadable(() => import("./Penjadwalan/Penjadwalan"));
const DetilSesiMateri = Loadable(() => import("./Penjadwalan/DetilSesiMateri"));
const MateriPembelajaran = Loadable(() => import("./MataPelajaran/MateriPembelajaran"));
const DaftarMateriMapel = Loadable(() => import("./MataPelajaran/DaftarMateriMapel"));
const DetilSesiMateriMapel = Loadable(() => import("./MataPelajaran/DetilSesiMateriMapel"));

export default {
	Penjadwalan, DetilSesiMateri,
	MateriPembelajaran, DaftarMateriMapel, DetilSesiMateriMapel
};