import _ from 'lodash';
import { Icon, Label } from 'semantic-ui-react';

const NAMA_SEKOLAH = "DEFAULT_NAMA_SEKOLAH";
const JENJANG = "JEN"
const JENJANG_SEBELUMNYA = "JS"
const JENJANG_SEBELUMNYA_KECIL = JENJANG_SEBELUMNYA.toLowerCase();
const CONTACT_IMPULS = "081228800048";
const CONTACT_IMPULS_62 = "6281228800048";
const COLOR_THEME = 'orange'
const KODE_SEKOLAH_ORBIT = "orbit360"

const LOGIN_FORM = 'LOGIN_FORM';
const FORGET_FORM = 'FORGET_FORM';
const FETCH_INTERVAL = 8000 
const DEFAULT_KKM = 50;
const DEFAULT_DIFF = 10;
const MAX_FILE_UPLOAD_SIZE = 5;

const SESI_MULAI = 'MULAI';
const SESI_JEDA = 'JEDA';
const SESI_SELESAI = 'SELESAI';
const SESI_BELUM_MULAI = 'BELUM_MULAI';

const WIDTH_KOP_KECIL = 1120
const HEIGHT_KOP_KECIL = 260

const WIDTH_KOP_BESAR = 1600 
const HEIGHT_KOP_BESAR = 260

export const MAPEL_CAMPUR = "CAMPURAN"

const MAP_LEVEL = { C: "create", R: "read", U: "update", D: "delete" };

const LIST_NAMA_HARI = ["Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu", "Minggu"]

const COLOR_SHEET = {
	blue: "#3974FE",
	orange: "#FE9A06",
	green: "#44C07A",
	purple: "#9E0059",
	violet: "#390099",
	teal: "#00B5AD",
	red: "#FE0F06",
	brown: "#E57C04",
	grey: "#97979B",
	olive: "#B5CC18",
	yellow: "#F1C40F",
	pink: "#FF0054",
	black: "#32323A",
};

const OPTS_JENIS_KELAMIN = 'Laki-Laki Perempuan'.split(' ').map(o => ({
	key: _.snakeCase(o).toUpperCase(),
	value: _.first(o),
	text: o,
}));

const OPTS_JENJANG_PENDIDIKAN = 'TK SD SMP SMA UNIVERSITAS'.split(' ').map(o => ({
	key: _.snakeCase(o).toUpperCase(),
	value: o, text: o,
}));

const OPTS_WARNA = [
	{text: "Biru", value: "blue", key: "blue"},
	{text: "Hijau", value: "green", key: "green"},
	{text: "Kuning", value: "yellow", key: "yellow"},
	{text: "Merah", value: "red", key: "red"},
]
const OPTS_KEWARGANEGARAAN = [
	{ key: 'WNI' , text: 'Warga Negara Indonesia', value: 'Warga Negara Indonesia' },
	{ key: 'WNA' , text: 'Warga Negara Asing', value: 'Warga Negara Asing' },
]

const OPTS_PENERANGAN_RUMAH =[
	{ key: "Baik Sekali", value: "Baik Sekali", text: "Baik Sekali" },
	{ key: "Baik", value: "Baik", text: "Baik" },
	{ key: "Kurang", value: "Kurang", text: "Kurang" },
	{ key: "Sangat Kurang", value: "Sangat Kurang", text: "Sangat Kurang" },
]

const OPTS_LINGKUNGAN_RUMAH = [
	{ key: "Perumahan", value: "Perumahan", text: "Perumahan" },
	{ key: "Perkantoran", value: "Dekat Perkantoran", text: "Dekat Perkantoran" },
	{ key: "Jalan Raya", value: "Dekat Jalan Raya", text: "Dekat Jalan Raya" },
]

const OPTS_STATUS_RUMAH = [
	{ key: "Orang Tua", value: "Rumah Pribadi/ bersama Orang Tua", text: "Rumah Pribadi/ bersama Orang Tua" },
	{ key: "Kerabat", value: "Rumah Kerabat", text: "Rumah Kerabat" },
	{ key: "Kos/ Kontrak", value: "Kos/ Kontrak", text: "Kos/ Kontrak" },
]

const OPTS_PENDIDIKAN = [
	{key: "Tidak/Belum Sekolah", text: "Tidak/Belum Sekolah", value: "Tidak/Belum Sekolah"},
	{key: "SD", text: "SD", value: "SD"},
	{key: "SMP", text: "SMP", value: "SMP"},
	{key: "SMA", text: "SMA", value: "SMA"},
	{key: "D1", text: "D1", value: "D1"},
	{key: "D2", text: "D2", value: "D2"},
	{key: "D3", text: "D3", value: "D3"},
	{key: "S1", text: "S1", value: "S1"},
	{key: "S2", text: "S2", value: "S2"},
	{key: "S3", text: "S3", value: "S3"},
]

const OPTS_AGAMA = [
	{ key:  'Islam', text: 'Islam', value: 'ISLAM' },
	{ key:  'Kristen', text: 'Kristen Protestan', value: 'KRISTEN PROTESTAN' },
	{ key:  'Katholik', text: 'Kristen Katholik', value: 'KRISTEN KATHOLIK' },
	{ key:  'Hindu', text: 'Hindu', value: 'HINDU' },
	{ key:  'Buddha', text: 'Buddha', value: 'BUDDHA' },
	{ key:  'Konghucu', text: 'Konghucu', value: 'KONGHUCU' },
	{ key:  'Kepercayaan', text: 'Kepercayaan', value: 'KEPERCAYAAN' },
]

const OPTS_PERIODE = 'SEMESTER INI-BULAN INI-MINGGU INI-HARI INI'.split('-').map(o => ({
	key: _.snakeCase(o).toUpperCase(),
	value: _.snakeCase(o).toUpperCase(),
	text: o.toLowerCase().split(' ').map(_.upperFirst).join(' '),
}));

const OPTS_TINGKAT = [
	{ key: 'TINGKAT_KABUPATENKOTA', value: "KABUPATENKOTA", text: "Kabupaten/Kota" },
	{ key: 'TINGKAT_PROVINSI', value: "PROVINSI", text: "Provinsi" },
	{ key: 'TINGKAT_NASIONAL', value: "NASIONAL", text: "Nasional" },
	{ key: 'TINGKAT_INTERNASIONAL', value: "INTERNASIONAL", text: "Internasional" },
];

const OPTS_JABATAN_EKTRAKURIKULER = [
	{ key: 'KETUA', value: "KETUA", text: "Ketua" },
	{ key: 'ANGGOTA', value: "ANGGOTA", text: "Anggota" },
];

const OPTS_KONTEN = [
	{ key: 'Ujian', value: "Ujian", text: "Ujian" },
	{ key: 'Materi', value: "Materi", text: "Materi" },
	{ key: 'Ujian & Materi', value: "Ujian & Materi", text: "Ujian & Materi" },
	{ key: 'Kosong', value: "Kosong", text: "Kosong" },
];

const OPTS_TIPE_MEDIA = [
	{ key: 'Artikel', value: "Artikel", text: "Artikel" },
	{ key: 'Audio', value: "Audio", text: "Audio" },
	{ key: 'Video', value: "Video", text: "Video" },
	{ key: 'Buku', value: "Buku", text: "Buku/Slide Presentasi" },
];

const OPTS_URUTAN = [
	{ key: 'Urutan Biasa', value: "Urutan Biasa", text: "Urutan Biasa" },
	{ key: 'Sesi Berikutnya', value: "Sesi Berikutnya", text: "Sesi Berikutnya" },
];

const OPTS_TIPE_VIDEO = [
	{ key: 'Unggah', value: "Unggah", text: "Unggah" },
	{ key: 'Youtube', value: "Youtube", text: "Youtube" },
];

const OPTS_NAMA_ORGANISASI = [
	{ key: "ORG", value: "ORG", text: "ORG" },
];

const OPTS_NILAI_PREDIKAT = [
	{ key: "A", value: "A", text: "A", content: "A" },
	{ key: "B", value: "B", text: "B", content: "B" },
	{ key: "C", value: "C", text: "C", content: "C" },
	{ key: "D", value: "D", text: "D", content: "D" },
	{ key: "E", value: "E", text: "E", content: "E" },
];

const OPTS_JENIS_PRESTASI = [
	{ key: "PRESTASI_LOMBA", value: "LOMBA", text: "Lomba" },
	{ key: "PRESTASI_ORGANISASI", value: "ORGANISASI", text: "Organisasi" },
	{ key: "PRESTASI_EKSTRAKURIKULER", value: "EKSTRAKURIKULER", text: "Ekstrakurikuler" },
	{ key: "PRESTASI_AKADEMIK", value: "AKADEMIK", text: "Akademik" },
	{ key: "PRESTASI_LAINNYA", value: "PRESTASI_LAINNYA", text: "Prestasi Lainnya" },
];

const OPTS_TUJUAN_PENGUMUMAN = [
	{key: "murid", value: "murid", text: "Murid" },
	{key: "wali_murid", value: "wali_murid", text: "Wali Murid" },
	{key: "karyawan", value: "karyawan", text: "Karyawan" }
]
const OPTS_ORTU = [
	{key: "Ayah", value: "ayah", text: "Ayah" },
	{key: "Ibu", value: "ibu", text: "Ibu" },
	{key: "Wali", value: "wali", text: "Wali" }
]

const OPTS_TIPE_NILAI_PENGETAHUAN = [
	// { key: "TLS", value: "TLS", text: "TLS", content: "Tulis" },
	// { key: "LSN", value: "LSN", text: "LSN", content: "Lisan" },
	{ key: "TGS", value: "TGS", text: "TGS", content: "Tugas" },
	{ key: "ULG", value: "ULG", text: "ULG", content: "Ulangan" },
];

const OPTS_TIPE_NILAI_SIKAP = [
	{ key: "OBS", value: "OBSERVASI", text: "Observasi", content: "Observasi" },
	{ key: "DRI", value: "DIRI", text: "Diri", content: "Diri" },
	{ key: "ATR", value: "ANTAR TEMAN", text: "Teman", content: "Antar Teman" },
	{ key: "JRL", value: "JURNAL", text: "Jurnal", content: "Jurnal" },
];

const OPTS_TIPE_NILAI_KETERAMPILAN = [
	{ key: "PTK", value: "PTK", text: "PTK", content: "Praktik" },
	{ key: "PRD", value: "PRD", text: "PRD", content: "Produk" },
	{ key: "PRT", value: "PRT", text: "PRT", content: "Portofolio" },
	{ key: "PRY", value: "PRY", text: "PRY", content: "Proyek" },
];

const OPTS_JALUR_MASUK = [
	{ key: "PRESTASI", value: "PRESTASI", text: "Prestasi" },
	{ key: "PRESTASI_2", value: "PRESTASI_2", text: "Prestasi 2" },
	{ key: "REGULER", value: "REGULER", text: "Reguler" },
	{ key: "REGULER_2", value: "REGULER_2", text: "Reguler 2" },
	{ key: "REGULER_2", value: "REGULER_3", text: "Reguler 3" },
]

const LIST_JENIS_JAWABAN = [
  {key:'PILIHAN_GANDA', value:'PILIHAN_GANDA', text:'Pilihan Ganda'},
  {key:'ISIAN_SINGKAT', value:'ISIAN_SINGKAT', text:'Isian Singkat'},
  {key:'URAIAN', value:'URAIAN', text:'Uraian'},
]

export const LIST_TINGKAT = [
	{ key: 'TINGKAT_I', value: 'I', text: 'Tingkat I', jenjang: ["SD", "UMUM"] },
	{ key: 'TINGKAT_II', value: 'II', text: 'Tingkat II', jenjang: ["SD", "UMUM"] },
	{ key: 'TINGKAT_III', value: 'III', text: 'Tingkat III', jenjang: ["SD", "UMUM"] },
	{ key: 'TINGKAT_IV', value: 'IV', text: 'Tingkat IV', jenjang: ["SD", "UMUM"] },
	{ key: 'TINGKAT_V', value: 'V', text: 'Tingkat V', jenjang: ["SD", "UMUM"] },
	{ key: 'TINGKAT_VI', value: 'VI', text: 'Tingkat VI', jenjang: ["SD", "UMUM"] },

	{ key: 'TINGKAT_VII', value: 'VII', text: 'Tingkat VII', jenjang: ["SMP", "UMUM"] },
	{ key: 'TINGKAT_VIII', value: 'VIII', text: 'Tingkat VIII', jenjang: ["SMP", "UMUM"] },
	{ key: 'TINGKAT_IX', value: 'IX', text: 'Tingkat IX', jenjang: ["SMP", "UMUM"] },

	{ key: 'TINGKAT_X', value: 'X', text: 'Tingkat X', jenjang: ["SMA", "UMUM"] },
	{ key: 'TINGKAT_XI', value: 'XI', text: 'Tingkat XI', jenjang: ["SMA", "UMUM"] },
	{ key: 'TINGKAT_XII', value: 'XII', text: 'Tingkat XII', jenjang: ["SMA", "UMUM"] },
]

const OPTS_COLOR = ["purple", "blue", "teal", "green", "yellow", "orange", "red"]

const LIST_JENIS_AKSES = [
  {key:'ORBIT360', value:'ORBIT360', text: (<span><Label color="orange">ORBIT360</Label></span>)},
  {key:'PRIBADI', value:'PRIBADI', text: (<span><Label color="grey"><Icon name="user" />Pribadi</Label></span>)},
  {key:'UMUM', value:'UMUM', text: (<span><Label color="teal"><Icon name="users" />Umum</Label></span>)},
]

const OPTS_KELAS_TINGKAT = "T1 T2 T3".split(" ").map(v => ({ key: v, text: `Tingkat ${v}`, value: v }));
const OPTS_KELAS_PROGRAM = "P1 P2".split(" ").map(v => ({ key: v, text: v, value: v }));

const IMAGE_SMALL = 100;
const IMAGE_MEDIUM = 150;
const IMAGE_HIGH = 300;

const POIN_LIMIT = 0;
const BATAS_POIN_PELANGGARAN = 500;
const OPTS_TATIB_WAKTU = OPTS_PERIODE;

const COLOR_BUTTON_EDIT = "blue";

const PRECISION = 2

const OPTIONS = {
	OPTS_WARNA,
	OPTS_COLOR,
	OPTS_AGAMA,
	OPTS_TINGKAT,
	OPTS_PERIODE,
	OPTS_PENDIDIKAN,
	OPTS_TATIB_WAKTU,
	OPTS_JENIS_PRESTASI,
	OPTS_JENIS_KELAMIN,
	OPTS_JENJANG_PENDIDIKAN,
	OPTS_NAMA_ORGANISASI,
	OPTS_PENERANGAN_RUMAH,
	OPTS_JABATAN_EKTRAKURIKULER,
	OPTS_TIPE_NILAI_PENGETAHUAN,
	OPTS_TIPE_NILAI_SIKAP,
	OPTS_KEWARGANEGARAAN,
	OPTS_TIPE_NILAI_KETERAMPILAN,
	OPTS_NILAI_PREDIKAT,
	OPTS_KELAS_TINGKAT,
	OPTS_STATUS_RUMAH, 
	OPTS_LINGKUNGAN_RUMAH,
	OPTS_KELAS_PROGRAM,
	OPTS_JALUR_MASUK,
	OPTS_TUJUAN_PENGUMUMAN,
	OPTS_ORTU,
	OPTS_TIPE_MEDIA,
	OPTS_TIPE_VIDEO,
	OPTS_KONTEN,
	OPTS_URUTAN,
};

export {
	COLOR_THEME,
	NAMA_SEKOLAH,
	CONTACT_IMPULS,
	COLOR_BUTTON_EDIT,
	LIST_JENIS_AKSES,
	OPTIONS,
	POIN_LIMIT,
	FETCH_INTERVAL,
	BATAS_POIN_PELANGGARAN,
	LOGIN_FORM,
	FORGET_FORM,
	DEFAULT_KKM,
	DEFAULT_DIFF,
	MAP_LEVEL,
	MAX_FILE_UPLOAD_SIZE,
	IMAGE_SMALL,
	IMAGE_MEDIUM,
	IMAGE_HIGH,
	JENJANG,
	LIST_NAMA_HARI,
	JENJANG_SEBELUMNYA,
	JENJANG_SEBELUMNYA_KECIL,
	CONTACT_IMPULS_62,
	LIST_JENIS_JAWABAN,
	SESI_MULAI,
	SESI_JEDA,
	SESI_SELESAI,
	SESI_BELUM_MULAI,
	WIDTH_KOP_KECIL,
	HEIGHT_KOP_KECIL,
	WIDTH_KOP_BESAR,
	HEIGHT_KOP_BESAR,
	PRECISION,
	KODE_SEKOLAH_ORBIT,
	COLOR_SHEET
};
